<template>
  <div class="row">
    <div class="col-lg-12">
      Here Add Your HTML Content.....
    </div>
  </div>
</template>
<script>
// import { socialvue } from '../../config/pluginInit'

export default {
  name: 'BlankPage',
  mounted () {
    // socialvue.index()
  }
}
</script>
